#swiper-multi .swiper {
  /* display: flex !important;
  width: 500px !important;
  height: auto !important; */
  /* background-color: yellow !important; */
  /* //padding: 20px; */
  /* height: auto !important; */
  /* background-color: white !important; */
  /* padding-bottom: 20px !important; */
  /* width: 2000px !important; */

  /*background-color: yellow !important; */
  background: transparent !important;
}

#swiper-multi .swiper-slide {
  /* display: flex !important;
  
  font-size: 18px;
  background: white; */
  /*background-color: red !important;
  /* height: max-content !important;
  width: 20% !important;  */
  background: transparent !important;
  padding: 0px 40px !important;
}

#swiper-multi  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  /* bottom: 10px !important; */
}