:root {
    /* --modal-background: rgb(255, 255, 255); */
    --modal-background: red;
    --modal-color: #00e673;
    --modal-load: #00e673;
    --modal-load-transparent: rgba(0, 230, 115, 0);
  }



  .modal--avaliacao-app-container .modal-content {
      background: var(--modal-background);
      box-shadow: 0px 0px 32px rgb(0 0 0 / 25%);
  }
  .modal--avaliacao-app-container .modal-body {
      padding-top: 0 !important;
  }
  #sak--popupchat {
      overflow-x: hidden;
      padding-top: 20px !important;
  }
  /* .sak--modal-sm {
    max-width: 600px;
    background: var(--modal-background);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 32px;
  } */
  .sak--modal-sm hr {
    border-color: rgb(218 217 217 / 30%);
    filter: brightness(0.95);
      opacity: 0.07;
  }
  .sak--modal-sm.large {
    max-width: 1170px;
  }
  .sak--modal-sm > div > button {
    position: absolute;
    right: -15px;
    top: -15px;
    background: transparent;
    padding: 4px 8px;
    border-radius: 5px;
    border: none;
    transition: all 0.2s;
    cursor: pointer;
    z-index: 9999;
  }
  .sak--modal-sm > div > button:hover {
    background: #e1e4ef;
  }
  .sak--modal-sm > div > button i {
    color: #171b37;
  }
  .sak--modal-sm h5 {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #828bab;
  }
  .sak--modal-sm h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #171b37;
  }
  .sak--loading-ponto {
    position: relative;
    left: -9999px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--modal-load);
    color: var(--modal-load);
    box-shadow: 9999px 0 0 0 var(--modal-load);
    animation: sakLoadPonto 1s infinite linear;
    animation-delay: 0.1s;
  }
  .sak--loading-ponto::before,
  .sak--loading-ponto::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .sak--loading-ponto::before {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--modal-load);
    color: var(--modal-load);
    animation: sakLoadPontoBefore 1s infinite linear;
    animation-delay: 0s;
  }
  .sak--loading-ponto::after {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--modal-load);
    color: var(--modal-load);
    animation: sakLoadPontoAfter 1s infinite linear;
    animation-delay: 0.2s;
  }
  .sak--modal-sm #sak--popupchat {
    max-width: 1170px;
    height: calc(
      100vh - 200px
    ); /* Calcular altura de acordo ao seu projeto (base na logo do SAk) */
    max-height: calc(100vh - 200px);
    overflow-y: overlay;
    overflow-x: hidden;
    padding-top: 20px;
    padding-right: 20px;
  }
  .sak--modal-sm #sak--popupchat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(210 210 210 / 40%);
  }
  .sak--modal-sm #sak--popupchat::-webkit-scrollbar {
    width: 6px;
    background: rgb(185 185 185 / 10%);
  }
  #sak--popupchat {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  #sak--popupchat li {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    transition: all 1s;
    animation: novaMensagem forwards 0.5s;
  }
  #sak--popupchat li + li {
    margin-top: 15px;
  }
  #sak--popupchat li[data-me] {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto {
    background-color: white;
    color: white;
    box-shadow: 9999px 0 0 0 white;
    animation: sakLoadPontoWhite 1s infinite linear;
    animation-delay: 0.1s;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto::before,
  #sak--popupchat li[data-me] .sak--loading-ponto::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto::before {
    background-color: white;
    color: white;
    animation: sakLoadPontoBeforeWhite 1s infinite linear;
    animation-delay: 0s;
  }
  #sak--popupchat li[data-me] .sak--loading-ponto::after {
    background-color: white;
    color: white;
    animation: sakLoadPontoAfterWhite 1s infinite linear;
    animation-delay: 0.2s;
  }
  #sak--popupchat li > time {
    margin-left: 12px;
    font-size: 0.6rem;
    color: #2f334a;
  }
  #sak--popupchat li > span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px;
  }
  #sak--popupchat li > span time {
    font-size: 0.6rem;
    margin-top: 6px;
    color: #2f334a;
  }
  #sak--popupchat li > span img {
    max-width: 50px;
    width: 100%;
    max-height: 50px;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  #sak--popupchat li > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: #2f334a;
    font-weight: 400;
    padding: 1rem 1.5rem;
    border-radius: 0.75rem 0.75rem 0.75rem 0.125rem;
    font-size: 0.9rem;
    margin-bottom: 18px;
    animation: animatexto 0.5s ease-in-out forwards;
    box-shadow: 0 1px 8px 0 rgb(90 60 122 / 08%);
  }
  #sak--popupchat li > div strong {
    font-weight: 600;
    margin-left: 6px;
    margin-right: 6px;
  }
  #sak--popupchat li[data-me] > div {
    color: white;
    background: var(--modal-color);
    border-radius: 0.75rem 0.75rem 0.125rem 0.75rem;
  }
  #sak--popupchat li > div video {
    max-width: 300px;
    border-radius: 0.75rem 0.75rem 0.75rem 0.125rem;
  }
  #sak--popupchat li .popup--videos {
    padding: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.125rem;
  }
  #sak--popupchat li .popup--buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: none;
  }
  #sak--popupchat li .popup--buttons span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  #sak--popupchat li .popup--buttons span a i {
    margin-right: 8px;
    font-size: 1.1rem;
    color: white;
    display: none;
  }
  #sak--popupchat li .popup--buttons span a {
    background: var(--modal-color);
    padding: 16px 24px;
    color: white;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
  }
  #sak--popupchat li .popup--buttons span a:hover {
    background: var(--modal-color);
    box-shadow: rgb(0 0 0 / 29%) 0px 6px 21px -11px,
      rgb(47 49 72 / 30%) 0px 13px 17px -16px;
  }
  #sak--popupchat li .popup--buttons span a + a {
    margin-left: 12px;
  }
  #sak--popupchat li .popup--buttons h5 {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 0.7rem;
    color: black;
  }
  @keyframes animatexto {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes novaMensagem {
    from {
      transform: translateX(-60px);
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes sakLoadPonto {
    0% {
      box-shadow: 9999px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 9999px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @keyframes sakLoadPontoBefore {
    0% {
      box-shadow: 9984px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 9984px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @keyframes sakLoadPontoAfter {
    0% {
      box-shadow: 10014px -15px 0 0 var(--modal-load-transparent);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 var(--modal-load);
    }
    100% {
      box-shadow: 10014px 15px 0 0 var(--modal-load-transparent);
    }
  }
  @keyframes sakLoadPontoWhite {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 white;
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes sakLoadPontoBeforeWhite {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 white;
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes sakLoadPontoAfterWhite {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(255, 255, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 white;
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(255, 255, 255, 0);
    }
  }