:root {
	interpolate-size: allow-keywords;
}
.custom-scroll {
	scroll-behavior: smooth !important;
}
.custom-scroll::-webkit-scrollbar {
	width: 4px;
}

.custom-scroll.zero-scroll::-webkit-scrollbar {
	width: 0;
}

.custom-scroll::-webkit-scrollbar-track {
	background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
	background: rgb(229 231 235);
	border-radius: 0.75rem;
}

div:has(> .dropdown-empreender) {
	z-index: 2 !important;
}

/* [data-radix-popper-content-wrapper] {
	z-index: 10 !important;
}
[data-radix-popper-content-wrapper] {
	z-index: 10 !important;
} */