

.list-group-item {
  border-bottom: #eee !important;
  font-weight: 600;
}

.list-group-item:hover {
  background-color: #eee;
}

#logos {
  gap: 50px;
  padding: 50px;
  margin-bottom: 50px;
}

#logos img {
  height: 80px;
}

.list-group-item img {
  width: 35px;
}

#paises a {
  text-decoration: none;
}
