/* .swiper {

  height: auto !important;
  padding-bottom: 20px !important;
  
}

.swiper-slide {

  height: max-content !important;
  width: 100% !important; 
  
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px !important;
} */

#swiper-avaliar {
  width: 800px !important;
  height: 600px !important;
  background-color: transparent ;
  /* display: flex !important;
  ;;width: 500px !important;
  height: auto !important; */
  /* background-color: yellow !important; */
  /* //padding: 20px; */
  /* height: 400px !important; */
  /* background-color: white !important; */
  /* padding-bottom: 20px !important; */
  
}

#swiper-avaliar .swiper-slide {
  /* display: flex !important;
  width: 800px !important
  font-size: 18px;
  background: white; */
  /*background-color: white !important;*/
  /* height: max-content !important; */
  /* width: 100% !important;  */
  display: flex !important;
  justify-content: center !important;
  background-color: transparent !important;
}

#swiper-avaliar .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  /* bottom: 10px !important; */
}

#swiper-avaliar .swiper-button-next,
#swiper-avaliar .swiper-button-prev {

    /* width: 40px !important;
    height: 40px !important; */
}

#swiper-avaliar .swiper-button-next {
  /* margin-right: -10px !important;
  background-image: url("arrow-rigth-swiper.svg") !important;
  background-size: 30px;
  background-repeat: no-repeat; */
}

.swiper-button-prev {
  /* margin-left: -10px !important;
  background-image: url("arrow-left-swiper.svg") !important;
  background-size: 30px;
  background-repeat: no-repeat; */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  /* content: ""; */
}

